import { ResponsiveType, SpacingsVariants } from '@sport1/news-styleguide/Types'

export const TOP_DISPLAY_HEIGHT = 657

export const TOP_MEDIA_MAX_WIDTH = 968
export const TOP_MEDIA_MAX_WIDTH_MOBILE = 468
export const TOP_MEDIA_MAX_HEIGHT = 546
export const TOP_MEDIA_MAX_HEIGHT_MOBILE = 263

export const TEASER_CARD_MAX_ITEMS = 9
export const MEDIATHEK_TEASER_CARD_MAX_ITEMS = 24
export const MEDIATHEK_HERO_OPTION_MAX_ITEMS = 6
export const WITHOUT_RIGHT_COLUMN_TEASER_CARD_MAX_ITEMS = 10
export const EDITORIAL_TEASER_CARD_MAX_ITEMS = 10
export const PAGING_COMPONENT_SIZE = 20

export enum HeadlineTypeEnum {
    popular = 'popular',
}

export const HEADLINE_TYPE_MAP: Map<string | undefined, string | undefined> = new Map([
    [HeadlineTypeEnum.popular, 'Oft gesucht'],
])

export const DEFAULT_OG_IMAGE = '/images/sport1-default-og.jpg'

export const GLOBAL_LAYOUT_COMPONENT_SPACE = [
    'spacing-7',
    'spacing-9',
    'spacing-10',
] as ResponsiveType<keyof SpacingsVariants>

export const GLOBAL_LAYOUT_COMPONENT_SPACE_CLASSNAME = 'mx-16 tn:mx-24 sm:mx-32'

export const HOME_MARKUP_JSON_LD = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    url: 'https://www.sport1.de/',
    name: 'SPORT1',
    headline: 'SPORT1 - Aktuelle Sportnachrichten, News, Liveticker & Ergebnisse',
    description:
        'SPORT1 ist die Nummer 1-Plattform, wenn es um Livesport geht: Livestreams, Liveticker und aktuelle Berichterstattung aus allen Bereichen des Sports.',
    mainEntityOfPage: 'https://www.sport1.de/',
    publisher: {
        '@type': 'Organization',
        logo: 'https://www.sport1.de/favicon-196x196.png',
        sameAs: [
            'https://www.facebook.com/SPORT1News',
            'https://www.instagram.com/sport1news',
            'https://x.com/SPORT1',
            'https://www.tiktok.com/@sport1news',
            'https://www.youtube.com/user/SPORT1',
        ],
    },
}
